import arrowDownStrong from './arrow-down-strong.svg?sprite';
import arrowLargeBold from './arrow-large-bold.svg?sprite';
import arrowLeft from './arrow-left.svg?sprite';
import arrowLightDown from './arrow-light-down.svg?sprite';
import arrowRight from './arrow-right.svg?sprite';
import arrowSmall from './arrow-small.svg?sprite';
import arrowStrongRight from './arrow-strong-right.svg?sprite';
import bin from './bin.svg?sprite';
import burger from './burger.svg?sprite';
import calendar from './calendar.svg?sprite';
import cameraOutline from './camera-outline.svg?sprite';
import cartUsp from './cart-usp.svg?sprite';
import checkboxActive from './checkbox/checkbox-active.svg?sprite';
import checkboxDefault from './checkbox/checkbox-default.svg?sprite';
import checkMarkSuccess from './checkmark-success.svg?sprite';
import clear from './clear.svg?sprite';
import clock from './clock.svg?sprite';
import closeSmall from './close-small.svg?sprite';
import closeStrongSmall from './close-strong-small.svg?sprite';
import close from './close.svg?sprite';
import copy from './copy.svg?sprite';
import defaultProductImage from './default-product-image.svg?sprite';
import discount from './discount.svg?sprite';
import document from './document.svg?sprite';
import edit from './edit.svg?sprite';
import email from './email.svg?sprite';
import emptyCart from './empty-cart.svg?sprite';
import emptyOrders from './empty-orders.svg?sprite';
import error from './error.svg?sprite';
import extraInfo from './extra-info.svg?sprite';
import facebook from './facebook.svg?sprite';
import accountFfh from './ffh/accountFfh.svg?sprite';
import cartFfh from './ffh/cartFfh.svg?sprite';
import filteringSorting from './filtering-sorting.svg?sprite';
import freeReturn from './free-return.svg?sprite';
import giftCard from './gift-card.svg?sprite';
import accountH24Large from './h24/account-large.svg?sprite';
import cartH24V2Large from './h24/cart-v2-large.svg?sprite';
import cartH24 from './h24/cart.svg?sprite';
import heart from './heart.svg?sprite';
import history from './history.svg?sprite';
import homeClubLogo from './homeclub/logo.svg?sprite';
import info from './info.svg?sprite';
import instagramFlat from './instagram-flat.svg?sprite';
import instagram from './instagram.svg?sprite';
import invisible from './invisible.svg?sprite';
import landscapeOutline from './landscape-outline.svg?sprite';
import lightCheck from './light-check.svg?sprite';
import list from './list.svg?sprite';
import location from './location.svg?sprite';
import login from './login.svg?sprite';
import logout from './logout.svg?sprite';
import marketplace from './marketplace.svg?sprite';
import materials from './materials.svg?sprite';
import megaphone from './megaphone.svg?sprite';
import message from './message.svg?sprite';
import myAccount from './myaccount.svg?sprite';
import pcUsp from './pc-usp.svg?sprite';
import phone from './phone.svg?sprite';
import pin from './pin.svg?sprite';
import pinterest from './pinterest.svg?sprite';
import play from './play.svg?sprite';
import plusFilled from './plus-filled.svg?sprite';
import plusStrong from './plus-strong.svg?sprite';
import printer from './printer.svg?sprite';
import qrCode from './qr-code.svg?sprite';
import radioDefault from './radio-default.svg?sprite';
import radioSelected from './radio-selected.svg?sprite';
import returnArrow from './return-arrow.svg?sprite';
import returnPolicy from './return-policy.svg?sprite';
import searchFillSmall from './search-fill-small.svg?sprite';
import search from './search.svg?sprite';
import security from './security.svg?sprite';
import shareArrow from './share-arrow.svg?sprite';
import share from './share.svg?sprite';
import size from './size.svg?sprite';
import sort from './sort.svg?sprite';
import spinner from './spinner.svg?sprite';
import subscribe from './subscribe.svg?sprite';
import success from './success.svg?sprite';
import survey from './survey.svg?sprite';
import sustain from './sustain.svg?sprite';
import truckChristmas from './truck-christmas.svg?sprite';
import truckOlympic from './truck-olympic.svg?sprite';
import truck from './truck.svg?sprite';
import trustPilotStar from './trust-pilot-star.svg?sprite';
import trust from './trust.svg?sprite';
import videoCall from './video-call.svg?sprite';
import visible from './visible.svg?sprite';
import warning from './warning.svg?sprite';
import wishlistV2FilledLarge from './wishlist-v2-filled-large.svg?sprite';
import wishlistV2Filled from './wishlist-v2-filled.svg?sprite';
import wishlistV2Large from './wishlist-v2-large.svg?sprite';
import wishlistV2 from './wishlist-v2.svg?sprite';
import wishlist from './wishlist.svg?sprite';
import zoom from './zoom.svg?sprite';
export default {
    accountH24Large: accountH24Large,
    accountFfh: accountFfh,
    arrowLeft: arrowLeft,
    arrowSmall: arrowSmall,
    arrowRight: arrowRight,
    arrowLightDown: arrowLightDown,
    arrowDownStrong: arrowDownStrong,
    arrowStrongRight: arrowStrongRight,
    arrowLargeBold: arrowLargeBold,
    bin: bin,
    burger: burger,
    calendar: calendar,
    cameraOutline: cameraOutline,
    cartUsp: cartUsp,
    cartH24: cartH24,
    cartH24V2Large: cartH24V2Large,
    cartFfh: cartFfh,
    checkboxActive: checkboxActive,
    checkboxDefault: checkboxDefault,
    clear: clear,
    clock: clock,
    close: close,
    closeStrongSmall: closeStrongSmall,
    closeSmall: closeSmall,
    copy: copy,
    defaultProductImage: defaultProductImage,
    discount: discount,
    document: document,
    edit: edit,
    email: email,
    emptyCart: emptyCart,
    emptyOrders: emptyOrders,
    error: error,
    extraInfo: extraInfo,
    facebook: facebook,
    filteringSorting: filteringSorting,
    freeReturn: freeReturn,
    giftCard: giftCard,
    heart: heart,
    history: history,
    homeClubLogo: homeClubLogo,
    info: info,
    invisible: invisible,
    instagram: instagram,
    instagramFlat: instagramFlat,
    landscapeOutline: landscapeOutline,
    lightCheck: lightCheck,
    list: list,
    location: location,
    login: login,
    logout: logout,
    marketplace: marketplace,
    materials: materials,
    megaphone: megaphone,
    message: message,
    myAccount: myAccount,
    pcUsp: pcUsp,
    phone: phone,
    pinterest: pinterest,
    play: play,
    plusStrong: plusStrong,
    plusFilled: plusFilled,
    printer: printer,
    radioDefault: radioDefault,
    radioSelected: radioSelected,
    returnArrow: returnArrow,
    returnPolicy: returnPolicy,
    search: search,
    searchFillSmall: searchFillSmall,
    security: security,
    sort: sort,
    spinner: spinner,
    survey: survey,
    sustain: sustain,
    trustPilotStar: trustPilotStar,
    subscribe: subscribe,
    success: success,
    truck: truck,
    truckChristmas: truckChristmas,
    truckOlympic: truckOlympic,
    trust: trust,
    videoCall: videoCall,
    visible: visible,
    warning: warning,
    wishlist: wishlist,
    wishlistV2: wishlistV2,
    wishlistV2Filled: wishlistV2Filled,
    wishlistV2Large: wishlistV2Large,
    wishlistV2FilledLarge: wishlistV2FilledLarge,
    size: size,
    checkMarkSuccess: checkMarkSuccess,
    zoom: zoom,
    qrCode: qrCode,
    share: share,
    shareArrow: shareArrow,
    pin: pin,
};
