var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo } from 'react';
import styled from '@emotion/styled';
import { size } from 'styled-system';
import icons from '../assets/icons';
import { fill } from '../theme/system-utilities';
export var DEFAULT_ICON_SIZE = [24, null, null, 32];
export var Rotate;
(function (Rotate) {
    Rotate["Left"] = "rotate(0)";
    Rotate["Right"] = "rotate(180deg)";
    Rotate["Up"] = "rotate(90deg)";
    Rotate["Down"] = "rotate(-90deg)";
})(Rotate || (Rotate = {}));
var Svg = styled.svg(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n"], ["\n  ", ";\n  ", ";\n"])), fill, size);
/**
 * In order to add the new icon into the sprite
 * please add icons to common/assets/icons/index.ts
 */
var Icon = function (_a) {
    var className = _a.className, _b = _a.dataTestId, dataTestId = _b === void 0 ? 'icon' : _b, role = _a.role, title = _a.title, ariaHidden = _a.ariaHidden, ariaLabel = _a.ariaLabel, rotate = _a.rotate, sizeProp = _a.size, _c = _a.fill, fillProp = _c === void 0 ? 'sys.neutral.icon.default' : _c, props = __rest(_a, ["className", "dataTestId", "role", "title", "ariaHidden", "ariaLabel", "rotate", "size", "fill"]);
    var _d = 'icon' in props ? props.icon : icons[props.name], id = _d.id, viewBox = _d.viewBox, href = _d.href;
    var iconHref = href !== null && href !== void 0 ? href : "#".concat(id);
    return (React.createElement(Svg, __assign({ viewBox: viewBox, className: className, "data-testid": dataTestId, role: role, fill: fillProp, size: sizeProp }, (props.id && { id: props.id }), (props.onClick && { onClick: props.onClick }), (rotate && { style: { transform: rotate } }), (ariaHidden !== undefined && { 'aria-hidden': ariaHidden }), (ariaLabel && { 'aria-label': ariaLabel })),
        title && React.createElement("title", null, title),
        React.createElement("use", { href: iconHref })));
};
export default memo(Icon);
var templateObject_1;
