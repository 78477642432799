import { style } from 'styled-system';
var transformValueFromTheme = function (n, theme) {
    var val = theme[n];
    if (val !== 0) {
        val = val || n;
    }
    return typeof val !== 'string' ? "".concat(val, "px") : val;
};
var createStyleFn = function (variant) {
    return style(variant);
};
export var themeWidth = style({
    prop: 'themeWidth',
    cssProperty: 'width',
    key: 'space',
    transformValue: transformValueFromTheme,
});
export var themeMaxWidth = style({
    prop: 'themeMaxWidth',
    cssProperty: 'maxWidth',
    key: 'space',
    transformValue: transformValueFromTheme,
});
export var themeMinWidth = style({
    prop: 'themeMinWidth',
    cssProperty: 'minWidth',
    key: 'space',
    transformValue: transformValueFromTheme,
});
export var themeHeight = style({
    prop: 'themeHeight',
    cssProperty: 'height',
    key: 'space',
    transformValue: transformValueFromTheme,
});
export var themeMaxHeight = style({
    prop: 'themeMaxHeight',
    cssProperty: 'maxHeight',
    key: 'space',
    transformValue: transformValueFromTheme,
});
export var themeMinHeight = style({
    prop: 'themeMinHeight',
    cssProperty: 'minHeight',
    key: 'space',
    transformValue: transformValueFromTheme,
});
export var themeTop = style({
    prop: 'themeTop',
    cssProperty: 'top',
    key: 'space',
    transformValue: transformValueFromTheme,
});
export var themeRight = style({
    prop: 'themeRight',
    cssProperty: 'right',
    key: 'space',
    transformValue: transformValueFromTheme,
});
export var themeBottom = style({
    prop: 'themeBottom',
    cssProperty: 'bottom',
    key: 'space',
    transformValue: transformValueFromTheme,
});
export var themeLeft = style({
    prop: 'themeLeft',
    cssProperty: 'left',
    key: 'space',
    transformValue: transformValueFromTheme,
});
export var columns = style({
    prop: 'columns',
    cssProperty: 'columns',
});
export var cursor = style({
    prop: 'cursor',
    cssProperty: 'cursor',
});
export var boxSizing = style({
    prop: 'boxSizing',
});
export var flexGrow = style({
    prop: 'flexGrow',
});
export var flexShrink = style({
    prop: 'flexShrink',
});
export var flexGap = createStyleFn({
    prop: 'gap',
    key: 'space',
});
export var flexRowGap = createStyleFn({
    prop: 'rowGap',
    key: 'space',
});
export var flexColumnGap = createStyleFn({
    prop: 'columnGap',
    key: 'space',
});
export var textDecoration = style({
    prop: 'textDecoration',
});
export var textUnderlinePosition = style({
    prop: 'textUnderlinePosition',
});
export var textDecorationThickness = style({
    prop: 'textDecorationThickness',
});
export var whiteSpace = createStyleFn({
    prop: 'whiteSpace',
});
export var textOverflow = createStyleFn({
    prop: 'textOverflow',
});
export var textIndent = createStyleFn({
    prop: 'textIndent',
});
export var overflowY = createStyleFn({
    prop: 'overflowY',
});
export var overflowX = createStyleFn({
    prop: 'overflowX',
});
export var borderTopStyle = style({
    prop: 'borderTopStyle',
});
export var borderRightStyle = style({
    prop: 'borderRightStyle',
});
export var borderBottomStyle = style({
    prop: 'borderBottomStyle',
});
export var borderTopColor = style({
    prop: 'borderTopColor',
    key: 'colors',
});
export var borderBottomColor = style({
    prop: 'borderBottomColor',
    key: 'colors',
});
export var borderRightColor = style({
    prop: 'borderRightColor',
    key: 'colors',
});
export var borderRightWidth = style({
    prop: 'borderRightWidth',
    key: 'borderWidths',
});
export var borderLeftWidth = style({
    prop: 'borderLeftWidth',
    key: 'borderWidths',
});
export var borderTopWidth = style({
    prop: 'borderTopWidth',
    key: 'borderWidths',
});
export var borderBottomWidth = style({
    prop: 'borderBottomWidth',
    key: 'borderWidths',
});
export var pointerEvents = style({
    prop: 'pointerEvents',
});
export var fill = createStyleFn({
    prop: 'fill',
    key: 'colors',
});
export var filter = style({
    prop: 'filter',
});
export var stroke = style({
    prop: 'stroke',
    key: 'colors',
});
export var strokeWidth = style({
    prop: 'strokeWidth',
    key: 'space',
});
export var borderRadiusTR = style({
    prop: 'borderRadiusTR',
    cssProperty: 'borderTopRightRadius',
    key: 'radii',
    transformValue: transformValueFromTheme,
});
export var borderRadiusTL = style({
    prop: 'borderRadiusTL',
    cssProperty: 'borderTopLeftRadius',
    key: 'radii',
    transformValue: transformValueFromTheme,
});
export var borderRadiusBR = style({
    prop: 'borderRadiusBR',
    cssProperty: 'borderBottomRightRadius',
    key: 'radii',
    transformValue: transformValueFromTheme,
});
export var borderRadiusBL = style({
    prop: 'borderRadiusBL',
    cssProperty: 'borderBottomLeftRadius',
    key: 'radii',
    transformValue: transformValueFromTheme,
});
export var translate3d = style({
    prop: 'translate3d',
    cssProperty: 'transform',
    transformValue: function (val) { return "translate3d(".concat(val, ")"); },
});
export var translateX = style({
    prop: 'translateX',
    cssProperty: 'transform',
    transformValue: function (val) { return "translateX(".concat(val, ")"); },
});
export var scale = style({
    prop: 'scale',
    cssProperty: 'transform',
    transformValue: function (val) { return "scale(".concat(val, ")"); },
});
export var rotate = style({
    prop: 'rotate',
    cssProperty: 'transform',
    transformValue: function (val) { return "rotate(".concat(val, "deg)"); },
});
export var maskImage = style({
    prop: 'maskImage',
    cssProperty: 'maskImage',
});
export var transition = style({
    prop: 'transition',
    cssProperty: 'transition',
});
export var transitionProperty = style({
    prop: 'transitionProperty',
    cssProperty: 'transitionProperty',
});
export var transitionTimingFunction = style({
    prop: 'transitionTimingFunction',
    cssProperty: 'transitionTimingFunction',
});
export var transitionDuration = style({
    prop: 'transitionDuration',
    cssProperty: 'transitionDuration',
});
export var transform = style({
    prop: 'transform',
});
export var textTransform = style({
    prop: 'textTransform',
    cssProperty: 'textTransform',
});
export var lineClamp = style({
    prop: 'lineClamp',
    cssProperty: 'WebkitLineClamp',
});
export var boxOrient = style({
    prop: 'boxOrient',
    cssProperty: 'WebkitBoxOrient',
});
export var listStyle = style({
    prop: 'listStyle',
});
export var wordBreak = createStyleFn({
    prop: 'wordBreak',
});
export var objectFit = style({
    prop: 'objectFit',
});
export var outline = style({
    prop: 'outline',
});
export var outlineColor = style({
    prop: 'outlineColor',
    key: 'colors',
});
export var themeBoxShadow = style({
    prop: 'boxShadow',
    key: 'boxShadows',
});
export var themeOpacity = style({
    prop: 'themeOpacity',
    cssProperty: 'opacity',
    key: 'opacity',
});
export var textShadow = style({
    prop: 'textShadow',
});
export var aspectRatio = style({
    prop: 'aspectRatio',
});
export var visibility = style({
    prop: 'visibility',
});
